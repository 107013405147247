import React from 'react';
import buderus from '../../assets/img/sponsor/buderus.png';
import bulex from '../../assets/img/sponsor/bulex.png';
import junkers from '../../assets/img/sponsor/junkers.png';
import remeha from '../../assets/img/sponsor/remeha.png';
import vaillant from '../../assets/img/sponsor/vaillant.png';
import viessmann from '../../assets/img/sponsor/viessmann.png';

const SponsorSection = () => {
  return (
    <div className="py-6 bg-white sm:py-8 lg:py-12 lg:pb-20">
      <div className="px-4 mx-auto max-w-screen-2xl md:px-8">
        <div className="flex flex-col items-center mb-4 md:mb-8 lg:flex-row lg:justify-center">
          <h2 className="mb-4 text-2xl font-bold text-center text-primary-800 lg:text-3xl">
            Nos marques partenaires
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 rounded-lg md:grid-cols-3 lg:gap-6">
          <div className="flex items-center justify-center h-16 p-4 text-gray-400 bg-gray-100 rounded-lg sm:h-32">
            <img
              src={buderus}
              alt="Logo Buderus, partenaire de Belgique chauffage entreprise de chauffage près de Nivelles, Wavre, Namur"
              width={250}
            />
          </div>

          <div className="flex items-center justify-center h-16 p-4 text-gray-400 bg-gray-100 rounded-lg sm:h-32">
            <img
              src={bulex}
              alt="Logo Bulex, partenaire de Belgique chauffage entreprise de chauffage près de Nivelles, Wavre, Namur"
              width={250}
            />
          </div>

          <div className="flex items-center justify-center h-16 p-4 text-gray-400 bg-gray-100 rounded-lg sm:h-32">
            <img
              src={junkers}
              alt="Logo Junkers, partenaire de Belgique chauffage entreprise de chauffage près de Nivelles, Wavre, Namur"
              width={250}
            />
          </div>

          <div className="flex items-center justify-center h-16 p-4 text-gray-400 bg-gray-100 rounded-lg sm:h-32">
            <img
              src={remeha}
              alt="Logo Remeha, partenaire de Belgique chauffage entreprise de chauffage près de Nivelles, Wavre, Namur"
              width={250}
            />
          </div>

          <div className="flex items-center justify-center h-16 p-4 text-gray-400 bg-gray-100 rounded-lg sm:h-32">
            <img
              src={vaillant}
              alt="Logo Vaillant, partenaire de Belgique chauffage entreprise de chauffage près de Nivelles, Wavre, Namur"
              width={250}
            />
          </div>

          <div className="flex items-center justify-center h-16 p-4 text-gray-400 bg-gray-100 rounded-lg sm:h-32">
            <img
              src={viessmann}
              alt="Logo Viessmann, partenaire de Belgique chauffage entreprise de chauffage près de Nivelles, Wavre, Namur"
              width={250}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorSection;
