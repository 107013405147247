import technician from '../../assets/img/technician.png'
const DepannageSectionPart = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 pt-14 sm:py-4 mx-auto flex flex-wrap">
        <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <img
              src={technician}
            alt="featur"
            className="object-cover object-center h-full w-full"

          />
        </div>
        <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full text-primary-500 mb-5">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Dépannage
              </h2>
              <p className="leading-relaxed text-base text-left">
                Nos techniciens chauffagiste, agréé et d'expérience dépannent toutes les marques de
                chaudières.
              </p>
              <a
                href="tel:0471/71.72.73"
                className="mt-4 inline-flex text-white bg-secondary-400 border-0 py-2 px-6 focus:outline-none hover:bg-secondary-600 rounded text-lg">
                Nous appeler
              </a>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="h-full px-16 py-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
              <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                Forfait dépannage
              </h2>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center pb-4 my-2 border-b border-gray-200">
                <span>€155(HTVA)</span>
                <span className="text-lg ml-1 font-normal text-gray-500">/1h</span>
              </h1>
              <p className="flex items-center text-gray-600 my-2">
                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-primary-400 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Main d'oeuvre
              </p>
              <p className="flex items-center text-gray-600 my-2 mb-4">
                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-primary-400 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Déplacement
              </p>

              <a
                href="/contact_form"
                className="flex items-center mt-auto text-white bg-secondary-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-secondary-600 rounded">
                Nous contacter
                <svg
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-auto"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DepannageSectionPart;
