import { FaFileInvoice, FaToilet } from 'react-icons/fa';
import { GiHeatHaze, GiWaterRecycling } from 'react-icons/gi';
import blob2 from '../../assets/svg/blob2.svg';

const ServiceListSection = () => {
  return (
    <section className="relative lg:text-white text-primary-800 body-font lg:mb-40">
      <div className="container z-20 px-5 md:py-8 pt-4 mx-auto ">
        <div className="relative mb-4 md:mb-12 text-center lg:mb-20">
          <h1 className="relative z-20 mb-4 text-2xl font-medium text-center md:text-center sm:text-3xl title-font">
            Nos autres services
          </h1>
          <p className="relative text-left z-20 mx-auto text-base leading-relaxed xl:w-2/4 lg:w-3/4">
            N'hésitez pas à prendre contact avec Belgique Chauffage ! Nos plombiers chauffagistes se
            tiennent à l'écoute de vos besoins afin de vous proposer des prestations de qualité qui
            soient adaptées à vos attentes. De plus, Belgique Chauffage vous fera bénéficier d'un
            devis gratuit pour l'ensemble de ses prestations.
          </p>
        </div>
        <div className="relative z-20 flex flex-wrap -mx-2 text-primary-600 lg:w-4/5 sm:mx-auto sm:mb-2">
          <a href="/chauffage/sol" className="w-full p-2 sm:w-1/2 hover:bg-">
            <div className="flex items-center h-full p-4 transition duration-300 ease-in bg-gray-100 border rounded border-primary-500 lg:border-2 hover:bg-secondary-500 active:text-white hover:text-white">
              <GiHeatHaze size={32} />
              <span className="ml-4 font-medium title-font">Chauffage au sol</span>
            </div>
          </a>
          <a href="/sanitaires" className="w-full p-2 sm:w-1/2 hover:bg-">
            <div className="flex items-center h-full p-4 transition duration-300 ease-in bg-gray-100 border rounded border-primary-500 lg:border-2 hover:bg-secondary-500 active:text-white hover:text-white">
              <FaToilet size={32} />
              <span className="ml-4 font-medium title-font">Sanitaires</span>
            </div>
          </a>
          <a href="/adoucisseur" className="w-full p-2 sm:w-1/2 hover:bg-">
            <div className="flex items-center h-full p-4 transition duration-300 ease-in bg-gray-100 border rounded border-primary-500 lg:border-2 hover:bg-secondary-500 active:text-white hover:text-white">
              <GiWaterRecycling size={32} />
              <span className="ml-4 font-medium title-font">Adoucisseur d'eau</span>
            </div>
          </a>
          <a href="/entretien/contrats" className="w-full p-2 sm:w-1/2 hover:bg-">
            <div className="flex items-center h-full p-4 transition duration-300 ease-in bg-gray-100 border rounded border-primary-500 lg:border-2 hover:bg-secondary-500 active:text-white hover:text-white">
              <FaFileInvoice size={32} />
              <span className="ml-4 font-medium title-font">Contrat d'entretien</span>
            </div>
          </a>
        </div>
        <a
          href="/contact"
          className="w-fit relative z-20 flex px-12 py-4 mx-auto mt-4 md:mt-16 text-xl text-white transition duration-300 ease-in border-0 rounded bg-secondary-500 focus:outline-none hover:bg-secondary-600">
          Contact
        </a>
      </div>
      <img
        src={blob2}
        width={1420}
        alt="Blob, Belgique Chauffage, Entreprise de chauffage près de Nivelles, Wavre, Namur"
        className="absolute top-0 left-0 right-0 hidden m-auto lg:block"
      />
    </section>
  );
};

export default ServiceListSection;
