//// TODO : UPDATE LINK AND CREATE MISSING VIEWS

const EntreprisePage = () => {
  return (
    <section className="pt-6 overflow-hidden text-gray-600 body-font md:pt-14">
      <div className="container px-5 py-24 mx-auto">
        <div className="-my-8 divide-y-2 divide-gray-100">
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium capitalize text-primary-600 title-font">
                Belgique chauffage : chauffagistes agréés couvrant toute la Wallonie et Bruxelles !
              </h2>
              <p className="leading-relaxed">
                Belgiquechauffage Entretien /Dépannage 24/7j Installation chaudière . Nos
                techniciens agréés et expérimentés sont à votre service ! Entretien gaz: 129€
                Entretien mazout :175€ Plombier chauffagiste près d'Ottignies Belgique Chauffage
                vous propose ses services pour l'installation, l'entretien et le dépannage de votre
                système de chauffage et de votre installation sanitaire dans toute la...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium text-primary-600 title-font">
                Entreprise de chauffage en Wallonie - installation de chaudières et planchers
                chauffants
              </h2>
              <p className="leading-relaxed">
                Plombier-chauffagiste près de Wavre et Namur (Ottignies), Belgique chauffage vous
                propose ses services pour l'installation, l'entretien et le dépannage de votre
                système de chauffage au gaz ou de votre système de chauffage au mazout près de
                Nivelles, Wavre et Mons. Forte de 9 ans d’expérience dans le domaine du chauffage,
                Belgique chauffage est une entreprise agréée en gaz et mazout et intervient près...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium text-primary-600 title-font">
                Installation de chaudières et chauffages dans le Brabant-Wallon, le Hainaut et la
                Région de Bruxelles
              </h2>
              <p className="leading-relaxed">
                Plombier-chauffagiste près de Wavre et Namur (Ottignies), Belgique chauffage assure
                l'installation de systèmes de chauffage au gaz ou au mazout près de Nivelles, Wavre
                et Mons en Wallonie. Besoin d'un chauffagiste capable d'installer rapidement votre
                chaudière ? Notre équipe agréée effectue l'installation de chaudières au gaz et au
                mazout et l'installation de chauffage au sol, dans les règles de l'art et selon
                la...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium text-primary-600 title-font">
                Installation chauffage sol .
              </h2>
              <p className="leading-relaxed">
                Plombier-chauffagiste près de Wavre et Namur (Ottignies), Belgique chauffage assure
                l'installation de chauffages au sol près de Nivelles, Wavre et Mons en Wallonie.
                Faites appel à l'équipe de plombiers chauffagistes de Belgique chauffage pour la
                pose de chauffage sol en Belgique. Lors de la pose, nous respectons l'ensemble des
                normes en vigueur afin d'assurer votre sécurité et la longévité de votre système
                de...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium text-primary-600 title-font">
                Entretien de chaudières gaz et de chaudières mazout près de Nivelles, Wavre et Mons
              </h2>
              <p className="leading-relaxed">
                Plombier-chauffagiste près de Wavre et Namur (Ottignies), Belgique chauffage assure
                l'entretien de tous types de chaudières (chaudière gaz à condensation ou
                atmosphérique, chaudière au mazout à condensation ou non), de convecteurs gaz et de
                cheminées. Notre équipe de chauffagistes prend en charge l'entretien de chauffages
                de grandes marques (Vaillant, Buderus, Viessmann, Junkers) depuis 12 ans. Sérieux
                et...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium text-primary-600 title-font">
                Belgique chauffage effectue le ramonage de votre cheminée près de Nivelles, Wavre et
                Mons
              </h2>
              <p className="leading-relaxed">
                Plombier-chauffagiste près de Wavre et Namur (Ottignies), Belgique chauffage met son
                savoir-faire à votre disposition pour effectuer le ramonage de cheminée à proximité
                de Nivelles, Wavre et Mons en Wallonie. Ramoner votre cheminée est une opération
                nécessaire pour entretenir votre conduit de cheminée et éviter les risques
                d'incendie et d'intoxication potentiels. Le ramonage d'une cheminée est
                obligatoire...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap py-8 md:flex-nowrap">
            <div className="md:flex-grow">
              <h2 className="mb-2 text-2xl font-medium text-primary-600 title-font">
                Contrats d'entretien de chaudière mazout et gaz près de Namur, Bruxelles et
                Charleroi
              </h2>
              <p className="leading-relaxed">
                Plombier-chauffagiste près de Wavre et Namur (Ottignies), Belgique chauffage vous
                propose de réaliser un contrat d'entretien de chaudière pour assurer votre sécurité
                ainsi que la bonne conformité de vos installations au fil du temps. Belgique
                chauffage intervient près de Wavre, Namur, Nivelles, Charleroi et Mons. Nos contrats
                d'entretien sont à un prix défiant toute concurrence ! Entretien...
              </p>
              <a className="inline-flex items-center mt-4 text-primary-500">
                En savoir plus
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  strokeLinecap="round"
                  stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntreprisePage;
