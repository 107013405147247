import React, { useState } from 'react';
import gallery01 from '../../assets/img/gallery/gallery-1.jpeg';
import gallery02 from '../../assets/img/gallery/gallery-2.jpeg';
import gallery03 from '../../assets/img/gallery/gallery-3.jpeg';
import gallery04 from '../../assets/img/gallery/gallery-4.jpeg';
import gallery05 from '../../assets/img/gallery/gallery-5.jpeg';
import gallery06 from '../../assets/img/gallery/gallery-6.jpeg';
import gallery07 from '../../assets/img/gallery/gallery-7.jpeg';
import gallery08 from '../../assets/img/gallery/gallery-8.jpeg';
import gallery09 from '../../assets/img/gallery/gallery-9.jpeg';
import gallery010 from '../../assets/img/gallery/gallery-10.jpeg';
import ImageModal from '../ImageModal/ImageModal';

const ImagesSection = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleImageSelection = (image: any) => {
    setIsModalOpen(true);
    setSelectedImage(image);
  };
  return (
    <section className="text-gray-600 body-font">
      <div className="py-6 sm:py-8 lg:py-12">
        <div className="px-4 mx-auto max-w-screen-2xl md:px-8">
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
            <div
              onClick={() => handleImageSelection(gallery01)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery01}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery02)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery02}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery03)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery03}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery04)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:col-span-2 md:h-80">
              <img
                src={gallery04}
                loading="lazy"
                alt="Photo by Martin Sanchez"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery05)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery05}
                loading="lazy"
                alt="Photo by Lorenzo Herrera"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery06)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery06}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery07)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery07}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery08)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery08}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery09)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
              <img
                src={gallery09}
                loading="lazy"
                alt="Photo by Minh Pham"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
            <div
              onClick={() => handleImageSelection(gallery010)}
              className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:col-span-2 md:h-80">
              <img
                src={gallery010}
                loading="lazy"
                alt="Photo by Magicle"
                className="absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110"
              />
              <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        image={selectedImage}
      />
    </section>
  );
};

export default ImagesSection;
