import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer2 } from './components/Footer/Footer2';
import FullPage from './components/HeroSection/FullPage';
import ImagesSection from './components/ImagesSection/ImagesSection';
import DepannageSectionPart from './components/MainFeature/DepannageSectionPart';
import EntretienSectionPart from './components/MainFeature/EntretienSectionPart';
import PlumberSectionPart from './components/MainFeature/PlumberSectionPart';
import { Navbar2 } from './components/Navbar/Navbar2';
import ServiceListSection from './components/ServiceListSection/ServiceListSection';
import Services from './components/Services/Services';
import SponsorSection from './components/SponsorSection/SponsorSection';
import {ContactForm} from "./components/ContactForm/ContactForm";
import InstallationPage from "./components/InstallationPage/InstallationPage";
import DepannagePage from "./components/DepannagePage/DepannagePage";
import EntreprisePage from "./components/EntreprisePage/EntreprisePage";
// import Contact from './pages/Contact'; // Page de contact
// import ChauffageChaudiere from './pages/ChauffageChaudiere'; // Exemple de page supplémentaire
// import ChauffageSol from './pages/ChauffageSol'; // Exemple de page supplémentaire
// import Plomberie from './pages/Plomberie'; // Exemple de page supplémentaire

const App: React.FC = () => {
    return (
        <Router>
            <div className="bg-[#F6F6F6] w-screen min-h-screen">
                <Navbar2 />
                <Routes>
                    <Route path="/" element={
                        <>
                            <FullPage />
                            <DepannageSectionPart />
                            <EntretienSectionPart />
                            <PlumberSectionPart />
                            <ServiceListSection />
                            <ImagesSection />
                            <SponsorSection />
                            <Services />
                        </>
                    } />
                    <Route path="/contact" element={<ContactForm />} />
                    <Route path="/chauffage/chaudiere" element={<EntreprisePage />} />
                    <Route path="/chauffage/sol" element={<FullPage />} />
                    <Route path="/plomberie" element={<PlumberSectionPart />} />
                    {/* Ajoute les autres routes pour chaque section du site */}
                </Routes>
                <Footer2 />
            </div>
        </Router>
    );
};

export default App;
