import { useState } from 'react';

export const Footer2 = () => {
  const [email, setEmail] = useState<string | null>(null);

  const handleSubscription = () => {
    if (email) {
      fetch('/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      }).then(() => {
        window.location.reload();
      });
    }
  };
  const year = new Date().getFullYear();
  return (
    <footer className="border-t text-primary-600 body-font border-primary-200">
      <div className="container px-5 py-12 mx-auto">
        <div className="grid flex-wrap grid-cols-2 -mx-4 -mb-10 text-center md:grid-cols-3 lg:flex md:text-left justify-center">
          <div className="w-full px-4 lg:w-1/6 md:w-1/2">
            <h2 className="mb-3 text-sm font-medium tracking-widest text-primary-900 title-font">
              Charleroi
            </h2>
            <nav className="mb-10 list-none">
              <li>
                <a href="/charleroi/entreprise" className="text-primary-600 hover:text-primary-800">
                  Entreprise
                </a>
              </li>
              <li>
                <a
                  href="/charleroi/installation"
                  className="text-primary-600 hover:text-primary-800">
                  Installation
                </a>
              </li>
              <li>
                <a href="/charleroi/entretien" className="text-primary-600 hover:text-primary-800">
                  Entretien
                </a>
              </li>
              <li>
                <a href="/charleroi/depannage" className="text-primary-600 hover:text-primary-800">
                  Dépannage
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-4 lg:w-1/6 md:w-1/2">
            <h2 className="mb-3 text-sm font-medium tracking-widest text-primary-900 title-font">
              Mons
            </h2>
            <nav className="mb-10 list-none">
              <li>
                <a href="/mons/entreprise" className="text-primary-600 hover:text-primary-800">
                  Entreprise
                </a>
              </li>
              <li>
                <a href="/mons/installation" className="text-primary-600 hover:text-primary-800">
                  Installation
                </a>
              </li>
              <li>
                <a href="/mons/entretien" className="text-primary-600 hover:text-primary-800">
                  Entretien
                </a>
              </li>
              <li>
                <a href="/mons/depannage" className="text-primary-600 hover:text-primary-800">
                  Dépannage
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-4 lg:w-1/6 md:w-1/2">
            <h2 className="mb-3 text-sm font-medium tracking-widest text-primary-900 title-font">
              Namur
            </h2>
            <nav className="mb-10 list-none">
              <li>
                <a href="/namur/entreprise" className="text-primary-600 hover:text-primary-800">
                  Entreprise
                </a>
              </li>
              <li>
                <a href="/namur/installation" className="text-primary-600 hover:text-primary-800">
                  Installation
                </a>
              </li>
              <li>
                <a href="/namur/entretien" className="text-primary-600 hover:text-primary-800">
                  Entretien
                </a>
              </li>
              <li>
                <a href="/namur/depannage" className="text-primary-600 hover:text-primary-800">
                  Dépannage
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-4 lg:w-1/6 md:w-1/2">
            <h2 className="mb-3 text-sm font-medium tracking-widest text-primary-900 title-font">
              Nivelles
            </h2>
            <nav className="mb-10 list-none">
              <li>
                <a href="/nivelles/entreprise" className="text-primary-600 hover:text-primary-800">
                  Entreprise
                </a>
              </li>
              <li>
                <a
                  href="/nivelles/installation"
                  className="text-primary-600 hover:text-primary-800">
                  Installation
                </a>
              </li>
              <li>
                <a href="/nivelles/entretien" className="text-primary-600 hover:text-primary-800">
                  Entretien
                </a>
              </li>
              <li>
                <a href="/nivelles/depannage" className="text-primary-600 hover:text-primary-800">
                  Dépannage
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-4 col-span-2 lg:w-1/6 md:w-1/2">
            <h2 className="mb-3 text-sm font-medium tracking-widest text-primary-900 title-font">
              Wavre
            </h2>
            <nav className="mb-10 list-none">
              <li>
                <a href="/wavre/entreprise" className="text-primary-600 hover:text-primary-800">
                  Entreprise
                </a>
              </li>
              <li>
                <a href="/wavre/installation" className="text-primary-600 hover:text-primary-800">
                  Installation
                </a>
              </li>
              <li>
                <a href="/wavre/entretien" className="text-primary-600 hover:text-primary-800">
                  Entretien
                </a>
              </li>
              <li>
                <a href="/wavre/depannage" className="text-primary-600 hover:text-primary-800">
                  Dépannage
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="border-t gap-x-4 border-primary-200 flex items-end justify-between px-4 lg:px-12 py-4 mx-auto">
        <div className="flex flex-wrap items-end justify-center md:flex-nowrap md:justify-start">
          <div className="relative w-40 mr-2 sm:w-64 sm:mr-4">
            <label htmlFor="footer-field" className="text-sm leading-7 text-primary-600">
              Votre e-mail
            </label>
            <input
              type="text"
              id="footer-field"
              name="footer-field"
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-1 text-base leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border rounded outline-none text-primary-700 bg-grey-100 border-primary-300 focus:ring-2 focus:bg-transparent focus:ring-primary-200 focus:border-primary-600"
            />
          </div>
          <div className="flex flex-col-reverse lg:flex-row lg:items-end">
            <button
              type="button"
              onClick={() => handleSubscription()}
              className="inline-flex px-6 py-2 text-white transition duration-300 ease-in border-0 rounded bg-secondary-600 focus:ring-2 focus:ring-primary-200 hover:bg-primary-400 ">
              Envoyer
            </button>
            <p className="my-2 text-sm text-primary-600 md:ml-6 md:my-0 sm:text-left">
              Inscrivez vous pour {''}
              <br className="hidden lg:block" />
              être au courant de nos promotions
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:grow md:items-center md:justify-around">
          <div className="flex flex-col items-center text-center text-primary-500 leading-normal text-sm">
            <a className="text-sm text-primary-500 my-2">info@belgiquechauffage.be</a>
            <p>
              Rue Alphonse Van Gricken,29.
              <br />
              Daussoulx, 5020
            </p>
          </div>
          <div className="flex flex-col justify-between items-center">
            <div className="flex justify-center w-full mt-4 mb-2 md:justify-center md:w-auto">
              <a className="text-primary-600">
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24">
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a className="ml-3 text-primary-600">
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24">
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a className="ml-3 text-primary-600">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24">
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a className="ml-3 text-primary-600">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24">
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-white bg-primary-600">
        <div className="container flex flex-col flex-wrap px-5 py-4 mx-auto sm:flex-row">
          <p className="text-sm text-center sm:text-left">© {year} Belgique Chauffage</p>
          <span className="w-full mt-2 text-sm text-center sm:ml-auto sm:mt-0 sm:w-auto sm:text-left">
            <a
              href="https://studio-114.be"
              className="ml-1 "
              target="_blank"
              rel="noopener noreferrer">
              Studio 114
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};
