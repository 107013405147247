

const PhoneIcon = () => {
    return (
        <div className="fixed bottom-4 right-4 z-10">
            <a href="tel:+32471717273" className="text-3xl text-gray-800 hover:text-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24"><path d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"/></svg>
            </a>
        </div>
    );
}

export default PhoneIcon;