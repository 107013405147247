import classnames from 'classnames';
import { useState } from 'react';

const EntretienSectionPart = () => {
  const [activeButton, setActiveButton] = useState('gaz');

  const handleButtonClick = () => {
    setActiveButton(activeButton === 'gaz' ? 'mazout' : 'gaz');
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-8 md:py-14 lg:flex-row flex-col items-center">
        <div>
          <div className="lg:flex-grow flex flex-col md:items-start md:text-left mb-4 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Entretien
            </h1>
            <p className="mb-8 leading-relaxed text-left">
              Nos techniciens chauffagistes agréés et expérimentés entretiennent toutes les marques
              de chaudière.
            </p>
            <div className="flex justify-between w-52">
              <button
                className={`${
                  activeButton === 'gaz'
                    ? 'inline-flex text-white bg-primary-500 border-0 py-2 px-6 focus:outline-none hover:bg-primary-600 rounded text-lg'
                    : 'inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg'
                }`}
                onClick={handleButtonClick}>
                Gaz
              </button>
              <button
                className={`${
                  activeButton === 'mazout'
                    ? 'inline-flex text-white bg-primary-500 border-0 py-2 px-6 focus:outline-none hover:bg-primary-600 rounded text-lg'
                    : 'inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg'
                }`}
                onClick={handleButtonClick}>
                Mazout
              </button>
            </div>
          </div>
        </div>

        <div className="w-full grow">
          <div className="flex items-center flex-col md:flex-row md:justify-evenly p-4 w-full">
            <div
              onClick={() => setActiveButton('gaz')}
              className={classnames(
                'h-full px-16 py-6 mx-4 my-4 md:my-0 rounded-lg border-2 flex flex-col relative overflow-hidden cursor-pointer',
                activeButton === 'gaz' ? 'border-primary-500 ' : 'border-gray-300 ',
              )}>
              <h2
                className={`${
                  activeButton === 'gaz'
                    ? 'text-sm tracking-widest title-font mb-1 font-medium border-primary-500    '
                    : 'text-sm tracking-widest title-font mb-1 font-medium'
                }`}>
                Gaz
              </h2>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center pb-4 my-2 border-b border-gray-200">
                <span>€149(HTVA)</span>
              </h1>
              <p className="flex items-center text-gray-600 my-2">
                <span
                  className={classnames(
                    'w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0',
                    activeButton === 'gaz' ? 'bg-primary-400' : 'bg-gray-400',
                  )}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Main d'oeuvre
              </p>
              <p className="flex items-center text-gray-600 my-2 mb-4">
                <span
                  className={classnames(
                    'w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0',
                    activeButton === 'gaz' ? 'bg-primary-400' : 'bg-gray-400',
                  )}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Déplacement
              </p>
              <a
                href="/contact_form?gaz"
                className={classnames(
                  'flex items-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none rounded',
                  activeButton === 'gaz'
                    ? 'bg-secondary-400 hover:bg-secondary-500'
                    : 'bg-gray-400 hover:bg-gray-500',
                )}>
                Nous contacter
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-auto"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div
              onClick={() => setActiveButton('mazout')}
              className={classnames(
                'h-full px-16 py-6 mx-4 rounded-lg border-2 flex flex-col relative overflow-hidden cursor-pointer',
                activeButton === 'mazout' ? 'border-primary-500 ' : 'border-gray-300 ',
              )}>
              <h2 className="text-sm tracking-widest title-font mb-1 font-medium">Mazout</h2>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center pb-4 my-2 border-b border-gray-200">
                <span>€195(HTVA)</span>
              </h1>
              <p className="flex items-center text-gray-600 my-2">
                <span
                  className={classnames(
                    'w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0',
                    activeButton === 'mazout' ? 'bg-primary-400' : 'bg-gray-400',
                  )}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Main d'oeuvre
              </p>
              <p className="flex items-center text-gray-600 my-2 mb-4">
                <span
                  className={classnames(
                    'w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0',
                    activeButton === 'mazout' ? 'bg-primary-400' : 'bg-gray-400',
                  )}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Déplacement
              </p>
              <a
                href="/contact_form?mazout"
                className={classnames(
                  'flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full mt-4 focus:outline-none  rounded',
                  activeButton === 'mazout'
                    ? 'bg-secondary-400 hover:bg-secondary-500'
                    : 'bg-gray-400 hover:bg-gray-500',
                )}>
                Nous contacter
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-auto"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntretienSectionPart;
