import { CgSmartHomeBoiler } from 'react-icons/cg';
import { FaFileInvoice } from 'react-icons/fa';
import { GiFireplace } from 'react-icons/gi';
import { MdPlumbing } from 'react-icons/md';

const Services = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-wrap -mx-4 -mt-4 -mb-10 space-y-6 sm:-m-4 md:space-y-0">
          <div className="flex flex-col items-center p-4 text-center md:w-1/4">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-20 h-20 mb-5 rounded-full bg-primary-600">
              <CgSmartHomeBoiler color="white" size={40} />
            </div>
            <div className="flex flex-col items-center flex-grow">
              <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">
                INSTALLATION DE CHAUDIÈRES
              </h2>
              <p className="text-base leading-relaxed text-left">
                Belgique Chauffage vous propose ses services pour l'installation de votre système de
                chauffage. Nos chauffagistes sont agréés pour effectuer l'installation de tous types
                de chaudières (chaudières au gaz, chaudières au mazout, chaudières à condensation,
                etc...) et pourront procéder à l'installation de radiateurs, convecteurs, etc...
              </p>
              <a
                href="/chauffage/chaudiere"
                className="flex items-end flex-grow mt-3 text-primary-500">
                En savoir plus
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center p-4 text-center md:w-1/4">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-20 h-20 mb-5 rounded-full bg-primary-600">
              <GiFireplace color="white" size={40} />
            </div>
            <div className="flex flex-col items-center flex-grow">
              <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">
                RAMONAGE DE CHEMINÉE
              </h2>
              <p className="text-base leading-relaxed text-left">
                Le ramonage de cheminée est obligatoire lors de l'entretien annuel de votre
                cheminée, il permet de vous protéger (risque d'incendie ou intoxication) tout en
                vous assurant du bon état de votre conduit de cheminée. Nos chauffagistes
                interviendront rapidement et vous délivreront le certificat de ramonage demandé par
                les assurances en cas de sinistre.
              </p>
              <a
                href="/entretien/ramonage"
                className="flex items-end flex-grow mt-3 text-primary-500">
                En savoir plus
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center p-4 text-center md:w-1/4">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-20 h-20 mb-5 rounded-full bg-primary-600">
              <FaFileInvoice color="white" size={40} />
            </div>
            <div className="flex flex-col items-center flex-grow">
              <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">
                CONTRAT D'ENTRETIEN
              </h2>
              <p className="text-base leading-relaxed text-left">
                Belgique Chauffage vous propose d'établir un contrat d'entretien pour votre
                chaudière au gaz ou votre chaudière au mazout. Ainsi, notre équipe de chauffagiste
                s'occupe de l'entretien complet de votre chaudière et vous recontacte chaque année
                lors de la date d'échéance de votre précédent entretien, ainsi vous n'avez plus
                besoin de vous en soucier !
              </p>
              <a
                href="/entretien/contrat"
                className="flex items-end flex-grow mt-3 text-primary-500">
                En savoir plus
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center p-4 text-center md:w-1/4">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-20 h-20 mb-5 rounded-full bg-primary-600">
              <MdPlumbing color="white" size={40} />
            </div>
            <div className="flex flex-col items-center flex-grow">
              <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">
                TRAVAUX DE PLOMBERIE
              </h2>
              <p className="text-base leading-relaxed text-left">
                L'équipe de Belgique Chauffage met également à votre disposition ses compétences en
                matière de plomberie. Nos plombiers chauffagistes vous garantissent une intervention
                rapide et soignée quelque soit votre besoin : réparation d'une fuite, rénovation du
                circuit de tuyauterie, installation de sanitaires divers, création de salle-de-bain,
                etc...
              </p>
              <a href="/plomberie" className="flex items-end flex-grow mt-3 text-primary-500">
                En savoir plus
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
