import React from 'react';
import star from '../../assets/svg/star.svg';
import technician from '../../assets/img/technician.png';

export const ContactForm = () => {
  return (
      <div className="w-screen flex items-center justify-center">
        <form className="bg-white p-12 rounded-2xl my-8 flex">
          <div className="flex flex-col mr-24 text-primary-700">
            <h1 className="text-primary-700 text-[2.7rem] leading-[3rem] font-[Viga]">
              Demande de contact
            </h1>
            <div className="flex flex-col items-start mt-3">
              <label className="ml-2 mb-1" htmlFor="name">
                Nom
              </label>
              <input
                  className="bg-[#F6F6F6] rounded-3xl p-2 px-4 w-full"
                  name="name"
                  id="name"
                  type="text"
              />
            </div>
            <div className="flex">
              <div className="flex flex-col items-start mt-3">
                <label className="ml-2 mb-1" htmlFor="email">
                  Adresse email
                </label>
                <input
                    className="bg-[#F6F6F6] rounded-3xl p-2 px-4"
                    type="email"
                    id="email"
                    name="email"
                />
              </div>
              <div className="flex flex-col items-start mt-2 ml-4">
                <label className="ml-2 mb-1" htmlFor="phone">
                  Téléphone
                </label>
                <input
                    className="bg-[#F6F6F6] rounded-3xl p-2 px-4"
                    type="tel"
                    id="phone"
                    name="phone"
                />
              </div>
            </div>
            <div className="flex flex-col items-start mt-3">
              <label className="ml-2 mb-1" htmlFor="address">
                Adresse
              </label>
              <input
                  className="bg-[#F6F6F6] rounded-3xl p-2 px-4 w-full"
                  type="text"
                  name="address"
                  id="address"
              />
            </div>
            <div className="flex flex-col items-start mt-3">
              <label className="ml-2 mb-1" htmlFor="service">
                Service
              </label>
              <select
                  className="bg-[#F6F6F6] rounded-3xl p-2 px-4 w-full"
                  id="service"
                  name="service">
                <option value=""></option>
                <option value=""></option>
                <option value=""></option>
              </select>
            </div>
            <button
                className="self-start bg-secondary-700 text-white py-2 px-12 rounded-full mt-4"
                type="submit">
              Envoyer
            </button>
          </div>
          <div>
            <div className="w-80 bg-primary-700 pt-16 rounded-bl-[1.5rem] rounded-tl-[1.5rem] rounded-tr-[1.5rem]">
              <div className="flex w-full px-12">
                <img src={star} width={16} className="mr-1" alt="Etoile d'évaluation" />
                <img src={star} width={16} className="mr-1" alt="Etoile d'évaluation" />
                <img src={star} width={16} className="mr-1" alt="Etoile d'évaluation" />
                <img src={star} width={16} className="mr-1" alt="Etoile d'évaluation" />
                <img src={star} width={16} className="mr-1" alt="Etoile d'évaluation" />
              </div>
              <p className="text-white text-sm text-lighter mt-2 px-12 leading-6 italic">
                “Nos plombiers chauffagistes se tiennent à l'écoute de vos besoins afin de vous
                proposer des prestations de qualité qui soient adaptées à vos attentes.”
              </p>
              <div className="flex mt-2 px-12 font-[Viga]">
                <img
                    src={technician}
                    alt="Belgique Chauffage - Technicien au travail"
                    className="rounded-md w-14 mr-4"
                />
                <div className="text-white flex flex-col">
                  <span>John Doerr</span>
                  <span className="uppercase text-xs">-Chauffagiste</span>
                </div>
              </div>
              <div className="flex mt-8 pl-12 w-full">
                <div className="flex">
                  <div className="w-8 h-8 bg-white rounded-full mr-2"></div>
                  <div className="w-8 h-8 bg-white rounded-full"></div>
                </div>
                <div className="ml-4 text-white flex flex-col font-[Viga] bg-secondary-700 pb-16 self-end w-full justify-end py-16 pl-8 rounded-tl-full rounded-bl-full rounded-tr-full">
                <span className="relative">
                  <span className="text-2xl font-semibold">1500</span>
                  <span className="absolute text-2xl -top-4 ml-2 font-semibold">+</span>
                </span>
                  <span className="-mt-1">Clients satisfaits</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  );
};
