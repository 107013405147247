import React from 'react';
import classnames from 'classnames';

type ImageModalProps = {
  image: string | null;
  isOpen: boolean;
  closeModal: () => void;
};

const ImageModal = ({ image, isOpen, closeModal }: ImageModalProps) => {
  return (
    <>
      {isOpen && <div className="fixed top-0 z-40 w-screen h-screen bg-black opacity-60"></div>}
      <div
        onClick={() => closeModal()}
        className={classnames(
          'fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden mx-auto overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full',
          !isOpen ? 'hidden' : 'block',
        )}>
        <div className="relative w-full h-full mx-auto opacity-100 lg:w-2/3 max-w-7xl md:h-auto">
          <div className="relative bg-white rounded-lg shadow opacity-100 ">
            <div className="flex items-center justify-between p-5 border-b rounded-t ">
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                data-modal-hide="extralarge-modal">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-primary-600"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              {image && (
                <img
                  src={image}
                  alt=""
                  className="inset-0 object-cover object-center w-full h-full "
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
