import classnames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RxCross1, RxHamburgerMenu } from 'react-icons/rx';
import logo from '../../assets/img/logo.png';
import PhoneIcon from '../PhoneIcon/PhoneIcon';

export const Navbar2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHeatOpen, setIsHeatOpen] = useState(false);
  const [isMaintenanceOpen, setIsMaintenanceOpen] = useState(false);

  const handleHeatSubmenu = () => {
    setIsHeatOpen((state) => !state);
    setIsMaintenanceOpen(false);
  };

  const handleMaintenanceSubmenu = () => {
    setIsHeatOpen(false);
    setIsMaintenanceOpen((state) => !state);
  };

  return (
      <header className="fixed z-50 w-full shadow-md bg-brandWhite-100 text-primary-500 body-font">
        <PhoneIcon />
        <div className="container relative z-50 flex items-center justify-between p-5 mx-auto">
          <Link to="/" className="flex items-center font-medium title-font text-primary-900">
            <img
                src={logo}
                width={50}
                alt="Logo de Belgique Chauffage, Entreprise de chauffage près de Nivelles, Wavre, Namur"
            />
            <h1 className="ml-3 text-xl text-primary-600">
              Belgique
              <br /> <span className="ml-2">Chauffage</span>
            </h1>
          </Link>
          <nav
              className={classnames(
                  'md:ml-auto flex-wrap items-center lg:text-xs xl:text-base justify-center lg:flex-row flex-col lg:items-center text-base lg:justify-end top-24 left-0 lg:top-0 w-full bg-primary-600 lg:bg-transparent text-white lg:text-primary-500 lg:relative fixed hover:cursor-pointer lg:flex',
                  !isOpen ? 'hidden' : 'flex',
              )}>
            <Link
                to="/"
                className="w-full py-3 mr-5 text-center lg:w-auto lg:py-0 hover:text-primary-900 focus:text-white focus:font-semibold">
              Accueil
            </Link>
            <div className="relative">
              <button
                  onClick={() => handleHeatSubmenu()}
                  className="flex items-center justify-center w-full py-2 pl-3 pr-4 mr-3 md:p-0 md:w-auto hover:text-primary-900 focus:text-white focus:font-semibold">
                Chauffage
                <svg
                    className="w-4 h-4 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                  <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
                </svg>
              </button>
              <div
                  className={classnames(
                      'w-full text-base list-none bg-transparent divide-y divide-gray-100 text-center mr-8 justify-center lg:absolute lg:top-8 lg:-left-4 lg:bg-brandWhite-100 lg:w-auto border border-primary-600',
                      !isHeatOpen ? 'hidden' : 'flex',
                  )}>
                <ul className="flex flex-col items-center py-1 lg:items-start lg:w-56">
                  <li>
                    <Link to="/chauffage/chaudiere" className="block px-4 py-2 text-sm">
                      Installation de chaudière
                    </Link>
                  </li>
                  <li>
                    <Link to="/chauffage/sol" className="block px-4 py-2 text-sm">
                      Chauffage au sol
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Link
                to="/plomberie"
                className="w-full py-3 mr-5 text-center lg:w-auto lg:py-0 hover:text-primary-900 focus:text-white focus:font-semibold">
              Plomberie
            </Link>
            <div className="relative">
              <button
                  onClick={() => handleMaintenanceSubmenu()}
                  className="flex items-center justify-center w-full py-2 pl-3 pr-4 mr-3 md:p-0 md:w-auto hover:text-primary-900 focus:text-white focus:font-semibold">
                Entretien
                <svg
                    className="w-4 h-4 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                  <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
                </svg>
              </button>
              <div
                  className={classnames(
                      'w-full text-base list-none bg-transparent divide-y divide-gray-100 text-center mr-4 justify-center lg:absolute lg:top-8 lg:-left-4 lg:bg-brandWhite-100 lg:w-auto border border-primary-600',
                      !isMaintenanceOpen ? 'hidden' : 'flex',
                  )}>
                <ul className="flex flex-col items-center py-1 lg:items-start lg:w-56">
                  <li>
                    <Link to="/entretien/ramonage" className="block px-4 py-2 text-sm">
                      Ramonage de cheminée
                    </Link>
                  </li>
                  <li>
                    <Link to="/entretien/contrats" className="block px-4 py-2 text-sm">
                      Contrats d'entretien
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Link
                to="/depannage"
                className="w-full py-3 mr-5 text-center lg:w-auto lg:py-0 hover:text-primary-900 focus:text-white focus:font-semibold">
              Dépannage
            </Link>
            <Link
                to="/sanitaires"
                className="w-full py-3 mr-5 text-center lg:w-auto lg:py-0 hover:text-primary-900 focus:text-white focus:font-semibold">
              Sanitaire
            </Link>
            <Link
                to="/adoucisseur"
                className="w-full py-3 mr-5 text-center lg:w-auto lg:py-0 hover:text-primary-900 focus:text-white focus:font-semibold">
              Adoucisseur d'eau
            </Link>
            <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 mb-4 mr-4 text-base text-white transition duration-300 ease-in border-0 rounded lg:mb-0 bg-secondary-400 focus:outline-none hover:bg-secondary-600 lg:mr-0">
              Contact
            </Link>
          </nav>
          <button className="text-4xl lg:hidden" onClick={() => setIsOpen((state) => !state)}>
            {isOpen ? <RxCross1 /> : <RxHamburgerMenu />}
          </button>
        </div>
      </header>
  );
};
